import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonDataState, initialState, TokenData } from './CommonDataState';






export const CommonDataSlice = createSlice({
    name: 'commonData',
    initialState,
    reducers: {
        setTokenExpiry: (state: CommonDataState, action: PayloadAction<TokenData>) => {
           
            const { tokenExpiry} = action.payload;
            state.tokenData.tokenExpiry = tokenExpiry;
           
        },
    },
});

export const { setTokenExpiry} = CommonDataSlice.actions;

export default CommonDataSlice.reducer;



 
 
