import React from 'react'
import { Add16Filled, Add20Filled } from '@fluentui/react-icons';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { Tooltip, Button, MenuTriggerChildProps } from '@fluentui/react-components';


const useClasses = makeStyles({
    smallContainer : {
        ...shorthands.borderRadius('50%'),
        display: 'flex', 
        flexDirection: 'row', 
         
    },
    smallDisabled : {
        ...shorthands.borderRadius('50%'),
        width: '35px',
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        cursor: 'not-allowed',
    },
    smallEnabled : {
        ...shorthands.borderRadius('50%'),
        width: '35px',
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#003F68',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    normal : {
        ...shorthands.borderRadius('20px'), 
        display:'flex', 
        alignItems:'center',
        fontSize:'16px',
        fontWeight: '600',
    },
    textWrapper : {
        display:'flex', 
        alignItems:'center', 
        paddingRight: '20px'
    },
    icon : {
        marginRight:'10px'
    },
    countText : {
        paddingLeft: '30px'
    }
  
})

interface CustomMenuTriggerProps extends Partial<MenuTriggerChildProps> {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    viewportWidth: number;
    thresholdPixelValue: number,
    chatCount: number;
    ALLOWEDCHATS: number
}

export const AddChatButton = React.forwardRef<HTMLButtonElement, CustomMenuTriggerProps>(({show, setShow, viewportWidth, thresholdPixelValue, chatCount, ALLOWEDCHATS}, ref) => {
    const classes = useClasses();

    return (
        <div style={{zIndex:'100'}}>
            {viewportWidth <= thresholdPixelValue ? (
                <div className={classes.smallContainer} style={{ transition: 'all 0.3s ease' }}>
                {chatCount >= ALLOWEDCHATS ? (
                    <Tooltip content="Remove a chat to create a new chat!" relationship="label">
                        <button
                            disabled={true}
                            className={classes.smallDisabled}
                            style={{transition: 'all 0.3s ease', border:'2px solid #AAAAAA'}}
                            ref = {ref}
                        >
                            <Add20Filled />
                        </button>
                    </Tooltip>
                ) : (
                    <button
                        onClick={() => {setShow(!show)}}
                        className={classes.smallEnabled}
                        style={{
                            transition: 'all 0.3s ease',                          
                            border: (show) ? '2px solid transparent' : '2px solid #003F68',                          
                            backgroundColor: (show) ? 'rgba(0, 63, 104, 0.4)' : 'transparent'
                        }}
                        ref = {ref}
                    >
                        <Add20Filled />
                    </button>
                )}
            </div>               
            ) : (
                <div style={{transition: "all 0.3s ease"}}>
                    {chatCount >= ALLOWEDCHATS ? (
                        <Tooltip content="Remove a chat to create a new chat!" relationship="label">
                            <Button
                            disabled={true}
                            data-testid="createNewConversationButton"
                            appearance="transparent"
                            className={classes.normal}
                            style={{ 
                                padding: '10px 20px 10px 14px',
                                transition: "all 0.3s ease",
                                backgroundColor: '#ffffff', 
                                border: '1px solid rgba(170, 170, 170, 0.4)',
                                color:'rgba(170, 170, 170, 0.75)',
                            }}
                            ref = {ref}
                            >
                        
                                <div style={{ display:'flex', alignItems:'center', paddingRight: '20px'}}>
                                    <Add16Filled style={{marginRight:'10px'}}/> 
                                    <span> New Chat </span>
                                </div>                                                                                               
                                <span style={{paddingLeft: '30px'}}> {chatCount}/{ALLOWEDCHATS} </span>
                            </Button>
                    </Tooltip>
                    ) : (
                        <Button
                            disabled={chatCount >= ALLOWEDCHATS ? true : false}
                            onMouseEnter={() => {setShow(true)}}
                            // onMouseLeave={() => {setHover(false)}}
                            // onClick={handleClick}
                            data-testid="createNewConversationButton"
                            appearance="transparent"
                            className={classes.normal}
                            style={{ 
                                // padding: ((chatCount >= ALLOWEDCHATS) || show) ? '10px 20px 10px 14px' : '10px 0px 10px 14px',
                                padding: (show) ? '10px 20px 10px 14px' : '10px 0px 10px 14px',
                                transition: "all 0.3s ease",
                                backgroundColor: (show) ? 'rgba(0, 63, 104, 0.4)' : '#ffffff',
                                border: (show) ? '1px solid transparent' : '1px solid #003F68',
                                color: '#003F68',
                                // backgroundColor: (hover || clicked) ? 'rgba(0, 63, 104, 0.4)' : '#ffffff', 
                                // border: chatCount >= ALLOWEDCHATS ? '1px solid rgba(170, 170, 170, 0.4)' : ((hover || clicked ) ? '1px solid transparent' : '1px solid #003F68'),
                                // color: chatCount >= ALLOWEDCHATS ? 'rgba(170, 170, 170, 0.75)' : '#003F68',
                            }}
                            ref = {ref}
                            >                      
                                <div className={classes.textWrapper}>
                                    <Add16Filled className={classes.icon}/> 
                                    <span> New Chat </span>
                                </div>                  
                                {(show) && <span className={classes.countText}> {chatCount}/{ALLOWEDCHATS} </span>}                                                                          
                        </Button>
                    )}                              
                </div>
            )}
        </div>
    )
}) as React.FC<CustomMenuTriggerProps>;  

AddChatButton.displayName = 'AddChatButton';  

