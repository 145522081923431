import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    //DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { Delete16 } from '../../../shared/BundledIcons';
import { useChat } from '../../../../libs/hooks';
import { useAppSelector, useAppDispatch } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { FeatureKeys } from '../../../../redux/features/app/AppState';
import { setSelectedConversation } from '../../../../redux/features/conversations/conversationsSlice';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IEditChatNameProps {
    chatId: string;
    chatName: string;
}

export const DeleteChatDialog: React.FC<IEditChatNameProps> = ({ chatId, chatName }) => {
    const classes = useClasses();
    const chat = useChat();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { features } = useAppSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();


    const onDeleteChat = () => {
        //console.log("Delete button pressed!");
        const chatIdArray = Object.keys(conversations);

        //before deleting conversation, determine next conversation to load
        let newSelectedConversation: string;
        if (chatIdArray.indexOf(selectedId) === 0){
            newSelectedConversation = chatIdArray[1]
        }
        else {
            newSelectedConversation = chatIdArray[0]
        }

        void chat.deleteChat(chatId)
            .then(() => {
                dispatch(setSelectedConversation(newSelectedConversation))
                if (conversations[newSelectedConversation].messages.length == 0)
                {
                    chat.loadChat(newSelectedConversation).then((succeeded) => {
                    if (succeeded) {
                        //console.log(new Date().toString());
                    }
                    }).catch((e: any) => {

                        const errorMessage = `Unable to get chat messages. Details: ${(e instanceof Error ? e.message : String(e))}`;
                        console.log(errorMessage);
                    });
                }
            });

    }

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Delete chat session'} relationship="label">
                    <Button
                        disabled={/*conversations[selectedId].messages.length === 0 ||*/ Object.keys(conversations).length <= 1 || !features[FeatureKeys.DeleteChats].enabled}
                        icon={<Delete16 />}
                        appearance="transparent"
                        aria-label="Edit"
                    />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure you want to delete chat {chatName}?</DialogTitle>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                onClick={onDeleteChat}
                            >
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
