export interface CommonDataState {
    tokenData:TokenData;
 }
 export interface TokenData{
     tokenExpiry: string;
 }
 
 export const initialState: CommonDataState = {
     tokenData:{"tokenExpiry":""}
     
 };