// import { makeStyles } from '@fluentui/react-components'
import { makeStyles, shorthands } from '@fluentui/react-components'
import React from 'react'
import starsIcon from '../../../assets/stars-icon.png'
import promptSuggestions from '../../../prompt-library.json'
import { ChatWindowBlock } from './ChatWindowBlock'

const useClasses = makeStyles({
    container: {
        // backgroundColor: '#eff2f8',
        width: '75%',
        '@media (max-width: 1410px)': {
            width: '90%',
        },
        height: '70%',
        display: 'flex',
        flexDirection: 'column',
    },
    greeting: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    greetingIcon: {
        ...shorthands.padding('10px', 0, 0, '15px'),
    },
    icon: {
        height: '90%',
        width: '90%'
    },
    greetingMessage: {
        width: '100%',
        fontSize: '40px',
        fontWeight: 'bold',
        ...shorthands.padding(0, 0, 0, '14px'),
        wordWrap: 'normal',
        lineHeight: '1'
        // wordBreak: 'break-word', // Add this line

    },
})

interface ChatWindowScreenProps {
    setValue: React.Dispatch<React.SetStateAction<string>>;
}


export const ChatWindowScreen: React.FC<ChatWindowScreenProps> = ({ setValue }) => {
    const classes = useClasses();

    return (
        <div className={classes.container}>
            <div className={classes.greeting}>
                <div className={classes.greetingIcon}>
                    <img className={classes.icon} src={starsIcon} />
                </div>
                <p className={classes.greetingMessage}> Hi, I&apos;m ProGPT! </p>
            </div>
            {Object.keys(promptSuggestions).map((parentCategory, parentIndex) => {
                const categorySuggestions = promptSuggestions[parentCategory as keyof typeof promptSuggestions];
                return (
                    <ChatWindowBlock
                        key={parentIndex}
                        category={parentCategory}
                        suggestions={categorySuggestions}
                        setValue={setValue}
                    />
                )
            })}
        </div>
    )
}

