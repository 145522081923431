/* eslint-disable padded-blocks */ 
import React from 'react';
import { Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
    Text,
} from '@fluentui/react-components';
import { ArrowDownload16Regular } from '@fluentui/react-icons';
import { makeStyles } from '@fluentui/react-components';
import * as XLSX from 'xlsx';
import PptxGenJS from "pptxgenjs";
import { asBlob } from 'html-docx-js-typescript';
import ExportUtil from '../../utils/ExportUtils';
import WordIcon from '../../../assets/icons8-microsoft-word-2019-48.png';
import ExcelIcon from '../../../assets/icons8-microsoft-excel-2019-48.png';
import PowerPointIcon from '../../../assets/icons8-microsoft-powerpoint-2019-48.png';

const useClasses = makeStyles({
    buttonContainer: {

    },
    menuButton: {

    },
    menuItemContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    menuImage:{
        width: '24px',
        height: '24px',
        paddingRight:'10px'
    },
})

interface ExportButtonProps {
    id: string | undefined;
    chatName: string;
    disableExcel: boolean;
}

const defaultText: PptxGenJS.TextProps = {
    text: "Default Text",
    options: {
        x: 0.0,
        y: 0.0,
        w:'100%',
        h: 1.5,
        align: 'left',
        fontSize: 14,
    }
};



const Export2Word = async (id: string | undefined, filename?: string) => {
    if (!id) {
        console.error("Invalid element ID");
        return;
    }

    const targetElement = document.getElementById(id);

    if (!targetElement) {
        console.error(`Element with ID '${id}' not found`);
        return;
    }

    const preHTML= '<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><title>Document</title></head><body>';
    const postHTML = '</body></html>';
    const content = targetElement.innerHTML;

    const html = preHTML + content + postHTML;
    try{
        const converted = await asBlob(html, { orientation: 'portrait'});

        const blob = new Blob([converted], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link to download the blob
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = filename ? `${filename}.docx` : 'document.docx';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log("Error")
    }

}

const Export2Excel = (id: string | undefined, filename?: string) => {

    if (!id) {
        console.error("Invalid element ID");
        return;
    }

    const targetElement = document.getElementById(id);

    if (!targetElement) {
        console.error(`Element with ID '${id}' not found`);
        return;
    }

    const tables = targetElement.getElementsByTagName('table');
    const tableArray = Array.from(tables);
    const wb = XLSX.utils.book_new();

    tableArray.forEach((table, index) => {
        const ws = XLSX.utils.table_to_sheet(table);
        const sheetName = `Table_${index+1}`;
        XLSX.utils.book_append_sheet(wb, ws, sheetName)
    })

    // Specify file name
    filename = filename ? filename + '.xlsx' : 'excel_data.xlsx';

    // Save the workbook to a file
    XLSX.writeFile(wb, filename);

}


const Export2PPTX = (id: string | undefined, filename: string) => {
    if (!id) {
        console.error("Invalid element ID");
        return;
    }

    const targetElement = document.getElementById(id);

    if (!targetElement) {
        console.error(`Element with ID '${id}' not found`);
        return;
    }

    const innerDiv = targetElement.querySelector('div');

    if (innerDiv) {
        const myObject = new ExportUtil({ data: innerDiv })
        myObject.createPPTX(filename)

    } else {
        const pptx = new PptxGenJS();
        const slide = pptx.addSlide();
        slide.addText([defaultText]);
        void pptx.writeFile({ fileName: "Error downloading" });
    }
}

export const ExportButton:React.FC<ExportButtonProps> = ({ id, chatName, disableExcel }) => {
    const classes = useClasses();
    const [hover, setHover] = React.useState("");

    const handleMouseEnter = (section: string) => {
        setHover(section);
    }

    const handleMouseLeave = () => {
        if (!id) {
            console.error("Invalid element ID");
            return;
        }
    }


    return (
        <div className={classes.buttonContainer}>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <Tooltip content="Export" relationship="label">
                        <Button
                            icon={<ArrowDownload16Regular />}
                            style={{border:'none'}}
                            className={classes.menuButton}
                        >
                        </Button>
                    </Tooltip>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={() => { void Export2Word(id, chatName) }}>
                            <div className={classes.menuItemContainer} id="Word" onMouseEnter={()=> {handleMouseEnter("Word")}} onMouseLeave={handleMouseLeave}>
                                <img src={WordIcon} alt="Word Icon" className={classes.menuImage} />
                                <Text style={{fontWeight: hover === "Word" ? 600 : 400}}>Word</Text>
                            </div>
                        </MenuItem>
                        {!disableExcel && (
                             <MenuItem onClick={() => { Export2Excel(id, chatName) }}>
                             <div className={classes.menuItemContainer} id="Excel" onMouseEnter={()=> {handleMouseEnter("Excel")}} onMouseLeave={handleMouseLeave}>
                                 <img src={ExcelIcon} alt="Excel Icon" className={classes.menuImage} />
                                 <Text style={{fontWeight: hover === "Excel" ? 600 : 400}}>Excel</Text>
                             </div>
                         </MenuItem>
                        )}
                        <MenuItem onClick={() => { Export2PPTX(id, chatName) }}>
                            <div className={classes.menuItemContainer} id="PowerPoint" onMouseEnter={()=> {handleMouseEnter("PowerPoint")}} onMouseLeave={handleMouseLeave}>
                                <img src={PowerPointIcon} alt="PowerPoint Icon" className={classes.menuImage} />
                                <Text style={{fontWeight: hover === "PowerPoint" ? 600 : 400}}>Powerpoint</Text>
                            </div>
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>

        </div>
    )
}
