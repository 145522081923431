
import { FC,   useCallback,   useEffect,  useState } from "react";
import {  useIdleTimer } from "react-idle-timer";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { AuthHelper } from "../../libs/auth/AuthHelper";
//import { useAppSelector } from "../../redux/app/hooks";
import { getStore } from "../../redux/app/store";

import { getOrCreateHubConnection } from '../../redux/features/message-relay/signalRHubConnection';
import { useMsal } from "@azure/msal-react";
interface IdleTimerProps{
  IsLoggedOut?:boolean
}

export const IdleTimerAlert1 :FC<IdleTimerProps>=({IsLoggedOut}) =>{  
  const [timeout] = useState<number>(3300000);//55minutes
  const [promptBeforeIdle] = useState<number>(120000);//last 2 minutes
  const { instance } = useMsal(); 
  const [state, setState] = useState<string>('Active')
  const [remaining, setRemaining] = useState<number>(timeout)
  const [open, setOpen] = useState<boolean>(false)

  const onIdle = () => {
    setState('Idle');
    setOpen(false);
    
    onLogout();
    console.log(IsLoggedOut,state);
  }

  const onActive = () => {
    setState('Active');
    setOpen(false);
   
  }

  const onPrompt = () => {
    setState('Prompted')
    setOpen(true)
  }
  // const onPresenceChange = (presence: PresenceType) => {
  //   console.log(presence);
  //   // const isIdle = presence.type === 'idle'
  //   // const isActive = presence.type === 'active' && !presence.prompted
  //   // const isPrompted = presence.type === 'active' && presence.prompted
  // }
  const { getRemainingTime,reset } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive, 
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })
  const onLogout = useCallback(() => {     
    //setOpen(false)  ;
    AuthHelper.logoutAsync(instance);
   
}, [instance]);


const onContinue =   () => {      
  setState('Active');   
  setOpen(false)  ;
 reset();
  //console.log(getRemainingTime());
 ( async ()=> {await AuthHelper.ssoSilentRequest(instance)});
 //eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
 getOrCreateHubConnection(getStore());
 
};

  

  return (

    <Dialog open={open}>
    
      {/* <DialogTrigger disableButtonEnhancement>        
        <Button  id="triggerPopup">{state}</Button>
      </DialogTrigger> */}
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Alert</DialogTitle>
          <DialogContent>
          Logging out in {remaining} seconds
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={()=>{onLogout()}}>Logout</Button>
            </DialogTrigger>
            <Button appearance="primary"  onClick={()=>{onContinue()}}>Continue</Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
    // <>
    //   <h1>React Idle Timer</h1>
    //   <h2>Confirm Prompt</h2>
    //   <br />
    //   <p>Current State: {state}</p>
    //   {timeTillPrompt > 0 && (
    //     <p>
    //       {timeTillPrompt} {seconds} until prompt
    //     </p>
    //   )}
    //   <div
    //     className='modal'
    //     style={{
    //       display: open ? 'flex' : 'none'
    //     }}>
    //     <h3>Are you still here?</h3>
    //     <p>Logging out in {remaining} seconds</p>
    //     <button onClick={handleStillHere}>Im still here</button>
    //   </div>
    // </>
  )
}


